import { ref, watch, computed } from '@vue/composition-api'

export default function useCommitmentHandler(props, emit) {
  // ------------------------------------------------
  // commitmentLocal
  // ------------------------------------------------

  const commitmentLocal = ref(JSON.parse(JSON.stringify(props.commitment.value)))
  
  const resetCommitmentLocal = () => {
    commitmentLocal.value = JSON.parse(JSON.stringify(props.commitment.value))
  }

  watch(props.commitment, () => {
    resetCommitmentLocal()
  })

  const behaviourReformatted = computed(() => commitmentLocal.value.behaviour.name?.replace(/<[^>]*>/g, ''))

  const onSubmit = () => {
    const commitmentData = JSON.parse(JSON.stringify(commitmentLocal))

    emit('update-commitment', commitmentData.value)

    // Close sidebar
    emit('update:is-commitment-handler-sidebar-active', false)
  }

  return {
    commitmentLocal,
    resetCommitmentLocal,

    // UI
    onSubmit,
    behaviourReformatted,
  }
}
