<template lang="pug">
.dashboard-wrapper
  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :customFiltersShow="true",
          :locationFilterShow="true",
          :roleFilterShow="true",
          :processFilterShow="true",
          :workerFilterShow="true",
          :locationDefaultValue="true",
          :dateRangeFilterShow="true",
          @changeFilter="updateFilter"
        )
        
  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          :color="colors.primary"
        )

  div(v-else)
    b-row(v-if="!workersTotal")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_workers_found') }}
            

    div(v-else)
      b-row
        b-col
          statistic-card-adherence(:statistic="adherenceTotal")
        b-col
          statistic-card-adjusted(:statistic="adherenceAdjusted")
        b-col
          statistic-card-commitments(:statistic="commitmentsGenerated")
        b-col
          statistic-card-compliance(:statistic="commitmentCompliance")
        b-col
          statistic-card-not-generated(:statistic="commitmentsNotGenerated")

      b-row
        b-col
          bar-chart-commitments(
            v-if="commitmentsData.labels && commitmentsData.labels.length",
            :commitments-data="commitmentsData"
          )

      b-card
        b-table-simple(hover, small, caption-top, responsive)
            caption
              b-card-sub-title(class="mb-2") {{ $t('failedBehaviorsTitle') }} 
            b-thead(head-variant="light")
              b-tr
                th {{ $t('message.tableHeader.worker') }}
                th {{ $t('message.tableHeader.creationDate') }}
                th {{ $t('message.tableHeader.process') }}
                th {{ $t('message.tableHeader.behaviour') }}
                th {{ $t('message.tableHeader.resolutionDate') }}
                th {{ $t('message.tableHeader.status') }}
                th {{ $t('message.tableHeader.commitments') }}
                th {{ $t('message.tableHeader.action') }}
              b-tr(v-for="(behaviour, index) in behaviourList", :key="index")
                b-td {{ behaviour.worker }}
                b-td {{ behaviour.creationDate }}
                b-td {{ behaviour.process }}
                b-td {{ behaviour.behaviourReplaced }}
                b-td {{ behaviour.resolutionDate }}
                b-td(:class="{'bold': true, 'text-success': behaviour.status === 'success', 'text': behaviour.status === 'failed', 'text-warning': behaviour.status === 'pending', 'text-danger': behaviour.status === 'overdue', 'text': behaviour.status === ''}") {{ behaviour.translatedStatus.value }}
                b-td {{ behaviour.commitmentsCount }}
                b-td
                  view-button(v-if="behaviour.status === 'success' || behaviour.status === 'failed'" variant="flat-success" @clicked="router.push({ name: 'habit-commitment-view', params: { commitmentId: behaviour.commitments[0]._id } })")
                  edit-button(v-if="behaviour.status === 'pending' || behaviour.status === 'overdue'" @clicked="router.push({ name: 'habit-commitment-new', params: { commitmentId: behaviour.commitments[0]._id, workerId: behaviour.workerId } })") 
                  navigation-button(v-if="behaviour.status === '' || behaviour.status === 'failed'" @clicked="openCommitmentSidebar(behaviour)")
      
      commitment-handler-sidebar(
        v-model="isCommitmentHandlerSidebarActive"
        :commitment="commitment"
        :clear-commitment-data="clearCommitmentData"
        :behaviourHasCommitment="false"
        handlerId="commitment"
        @update-commitment="addCommitment"
      )
</template>

<script>
import { onMounted, ref, computed } from "@vue/composition-api/dist/vue-composition-api"
import { BTableSimple, BThead, BTr, BTd, BCard, BCardSubTitle } from "bootstrap-vue"
import Filters from "@/views/organization/Filters.vue"
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import i18n from '@/libs/i18n'
import store from "@/store"
import StatisticCardAdherence from './charts/StatisticCardVerticalAdherence.vue'
import StatisticCardAdjusted from './charts/StatisticCardVerticalAdjusted.vue'
import StatisticCardCommitments from './charts/StatisticCardVerticalCommitments.vue'
import StatisticCardCompliance from './charts/StatisticCardVerticalCompliance.vue'
import StatisticCardNotGenerated from './charts/StatisticCardVerticalNotGenerated.vue'
import BarChartCommitments from './charts/ChartjsHorizontalBarChartCommitments'
import useCommon from '@/views/organization/useCommon'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import { useRouter } from '@core/utils/utils'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import CommitmentHandlerSidebar from '@/views/habit/confirmation/shared/sidebars/CommitmentHandlerSidebar.vue'
import useNotifications from '@/composables/useNotifications'
import NavigationButton from '@/views/components/Shared/Buttons/NavigationButton.vue'
import { colors } from '@/constants'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    Filters, 
    Loading,
    StatisticCardAdherence,
    StatisticCardAdjusted,
    StatisticCardCommitments,
    StatisticCardCompliance,
    StatisticCardNotGenerated,
    BarChartCommitments,
    ViewButton,
    EditButton,
    BCardSubTitle,
    CommitmentHandlerSidebar,
    NavigationButton
  },
  setup() {    
    const userData = store.state?.userStore?.userData
    const userId = userData.worker_id?.$oid
    const clientId = userData.role !== "admin" ? userData.client.$oid : null
    const { default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const { handleError, createCommitment } = useCommon()
    const { processAdherence, getDatesFromRange } = useCommonDashboards()
    const { showSuccessMessage } = useNotifications()
    const { router } = useRouter()
    const { invokeFunction, ObjectId } = realmConnection()
    const workersTotal = ref(0)
    const isLoading = ref(true)

    const userLocations = userData.locations?.length ? userData.locations : null;
    const locationFilter = ref(userLocations ? userLocations.map(e => e.value) : []);
    const roleFilter = ref([]);
    const processFilter = ref([]);
    const workerFilter = ref([]);

    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)

    const adherenceTotal = ref(0);
    const adherenceAdjusted = ref(0)
    const commitmentsGenerated = ref(0)
    const commitmentCompliance = ref(0)
    const commitmentsNotGenerated = ref(0)
    const commitmentsData = ref({})
    const behaviourList = ref([])

    async function listCommitments() {
      isLoading.value = true
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      try {
        const input = {
          clientId,
          userId,
          privileges: userData.role,
          locations: locationFilter.value,
          roles: roleFilter.value,
          processes: processFilter.value,
          workers: workerFilter.value,
          date_gte: startFilter,
          date_lt: endFilter
        }
      
        const items = await invokeFunction({ name: 'commitmentReport', arg: input })

        const workersWithCommitments = items?.commitments || []
        workersTotal.value = workersWithCommitments.length
        parseConfirmationData(workersWithCommitments)
      } catch (error) {
        console.log(error);
        handleError({ error, defaultMessage: i18n.t('message.err_worker_list') })
      } finally {
        isLoading.value = false
      }
    }

    function parseConfirmationData(workersWithCommitments) {
      const labels = []
      const processAdherenceValues = []
      const increasedAdherenceValues = []
      const unresolvedGapValues = []
      const gapWithoutCommitmentValues = []
      const workerIds = []

      let totalScoreCount = 0
      let totalTotalCount = 0
      let adjustedScoreCount = 0
      let unresolvedGapCount = 0
      let commitmentsGeneratedCount = 0
      let commitmentsNotGeneratedCount = 0

      const behaviourListArray = []

      for (const worker of workersWithCommitments) {
        if (worker.averageAdherence === null) continue

        const behavioursFailed = []  

        if (worker.averageAdherence < 1) {
          worker.confirmationData.forEach(e => {
            e.lastConfirmation?.activities?.forEach(a => {
              a.behaviours?.forEach(b => {
                if (b.type === "bool" && b.answer === "false") {
                  behavioursFailed.push({
                    activity: a.name,
                    behaviour: b.name
                  })
                  behaviourListArray.push({
                    worker: worker.name,
                    workerId: worker._id?.toString(),
                    process: e.processName,
                    activity: a.name,
                    behaviour: b.name,
                    behaviourReplaced: b.name?.replace(/<[^>]*>/g, ''),
                    type: b.type,
                    confirmationId: e.lastConfirmation.confirmationId?.toString() || null,
                    commitments: e.commitments.filter(c => c.activity === a.name && c.behaviour?.name === b.name),
                  })                  
                }
              })
            })
            
            e.commitments?.forEach(c => {
              if (c.completed && c.behaviour?.answer === "true" || !c.completed) {
                for (const behaviourFailed of behavioursFailed) {
                  if (behaviourFailed.activity === c.activity && behaviourFailed.behaviour === c.behaviour?.name) {
                    behaviourFailed.status = c.completed ? "success" : "pending"
                    break
                  }
                }
              }                      
            })
          })
        }

        const increasedAdherence = behavioursFailed.filter(e => e.status === "success").length
        const unresolvedGap = behavioursFailed.filter(e => e.status === "pending").length
        const gapWithoutCommitment = worker.totalTotal - worker.totalScore - increasedAdherence - unresolvedGap

        labels.push(worker.name)
        processAdherenceValues.push(processAdherence(worker.totalScore, worker.totalTotal))
        increasedAdherenceValues.push(processAdherence(increasedAdherence, worker.totalTotal))
        unresolvedGapValues.push(processAdherence(unresolvedGap, worker.totalTotal))
        gapWithoutCommitmentValues.push(processAdherence(gapWithoutCommitment, worker.totalTotal))
        workerIds.push(worker._id?.toString())

        totalScoreCount += worker.totalScore
        totalTotalCount += worker.totalTotal
        adjustedScoreCount += increasedAdherence
        unresolvedGapCount += unresolvedGap
        commitmentsGeneratedCount += increasedAdherence + unresolvedGap
        commitmentsNotGeneratedCount += behavioursFailed.length - increasedAdherence - unresolvedGap
      }

      adherenceTotal.value = `${processAdherence(totalScoreCount, totalTotalCount)}%`
      adherenceAdjusted.value = `${processAdherence(totalScoreCount + adjustedScoreCount, totalTotalCount)}%`
      commitmentsGenerated.value = commitmentsGeneratedCount
      commitmentCompliance.value = `${processAdherence(adjustedScoreCount, adjustedScoreCount + unresolvedGapCount)}%`
      commitmentsNotGenerated.value = commitmentsNotGeneratedCount

      commitmentsData.value = {
        labels: labels.slice(0,10),
        data: {
          processAdherenceValues: processAdherenceValues.slice(0,10),
          increasedAdherenceValues: increasedAdherenceValues.slice(0,10),
          unresolvedGapValues: unresolvedGapValues.slice(0,10),
          gapWithoutCommitmentValues: gapWithoutCommitmentValues.slice(0,10)
        },
        additionalInfo: {
          workerIds
        }
      }

      behaviourListArray.forEach(b => {
          const count = b.commitments?.length
          let status = ''
          let dueDate = ''
          let creationDate = null
          let resolutionDate = null
          if(count > 0) {
            const commitmentToShow = b.commitments[0]
            creationDate = commitmentToShow.creationDate ? commitmentToShow.creationDate : ''
            resolutionDate = commitmentToShow.resolutionDate ? commitmentToShow.resolutionDate : ''
            dueDate = commitmentToShow.dueDate
            status = commitmentToShow.completed 
              ? commitmentToShow.behaviour.answer === 'true' ? 'success' : 'failed'
              : dueDate < now ? 'overdue' : 'pending'
          }
                    
          // const resolutionDate = status === 'success' || status === 'failed' 
          //   ? dateFromObjectId(b.commitments[0]._id) : dueDate  
          const translatedStatus =
          status === 'success' ? computed(() => i18n.t('label.resolved')) :
          status === 'failed' ? computed(() => i18n.t('label.failed')) :
          status === 'overdue' ? computed(() => i18n.t('overdue')) :
          status === 'pending' ? computed(() => i18n.t('pending')) :
          computed(() => i18n.t('label.not_generated'))
          
          b.creationDate = creationDate ? creationDate.toLocaleDateString(`${default_language || 'en'}-US`) : '-'
          b.resolutionDate = resolutionDate ? resolutionDate.toLocaleDateString(`${default_language || 'en'}-US`) : '-'
          b.commitmentsCount = count        
          b.status = status
          b.translatedStatus = translatedStatus
      })
      behaviourList.value = behaviourListArray
    }

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      roleFilter.value = data.roleFilter;
      processFilter.value = data.processFilter;
      workerFilter.value = data.workerFilter;
      dateRangeFilter.value = data.dateRangeFilter;

      listCommitments();    
    }

    onMounted(() => {
      listCommitments()
    })

    // Commitment sidebar
    const isCommitmentHandlerSidebarActive = ref(false)

    const blankCommitment = {
      client_id: clientId,
      title: '',
      assignee: '',
      dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      activity: '',
      behaviour: {
        name: '',
        type: '',
      },
      // objective: '',
    }

    const commitment = ref(JSON.parse(JSON.stringify(blankCommitment)))

    const clearCommitmentData = () => {
      commitment.value = JSON.parse(JSON.stringify(blankCommitment))
    }

    const openCommitmentSidebar = (behaviour) => {
      commitment.value = {
        ...commitment.value,
        assignee: behaviour.workerId,
        activity: behaviour.activity,
        behaviour: {
          name: behaviour.behaviour,
          type: behaviour.type,
        },
        confirmation: behaviour.confirmationId
      }
      isCommitmentHandlerSidebarActive.value = true
    }

    const addCommitment = async (commitmentData) => {
      const creationDate = now.setHours(12, 0, 0, 0)
      isLoading.value = true
      try {
        commitmentData.client_id = ObjectId(commitmentData.client_id)
        commitmentData.assignee = ObjectId(commitmentData.assignee)
        commitmentData.supervisor = ObjectId(userId)
        commitmentData.dueDate = commitmentData.dueDate ? new Date(`${commitmentData.dueDate.slice(0, 10)} 12:00:00`) : null
        commitmentData.confirmation = commitmentData.confirmation ? ObjectId(commitmentData.confirmation) : null
        commitmentData.creationDate = new Date(creationDate)
        await createCommitment(commitmentData)
        showSuccessMessage(i18n.t('message.commitment_created'))
        listCommitments()
      } catch (error) {
        console.log(error)
        isLoading.value = false
      }
    }

    return {
      updateFilter,
      isLoading,
      adherenceTotal,
      adherenceAdjusted,
      commitmentsGenerated,
      commitmentCompliance,
      commitmentsNotGenerated,
      commitmentsData,
      workersTotal,
      behaviourList,
      router,

      // Commitment sidebar
      isCommitmentHandlerSidebarActive,
      commitment,
      clearCommitmentData,
      openCommitmentSidebar,
      addCommitment,

      colors
    };
  },
};
</script>

<style scoped>
.text {
  color: #c0c0c0
}

.bold {
  font-weight: 600;
}
</style>