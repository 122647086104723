<template>
  <div>
    <b-sidebar
      id="sidebar-commitment-handler"
      sidebar-class="sidebar-lg"
      :visible="isCommitmentHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-commitment-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ behaviourHasCommitment ? $t('update_commitment') : $t('add_commitment') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Title -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.Title')"
              rules="required"
            >
              <b-form-group
                :label="$t('label.Title')"
                label-for="task-title"
              >
                <b-form-input
                  v-model="commitmentLocal.title"
                  id="commitment-title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="$t('placeholder.commitment_title')"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Assignee -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.assignee')"
              rules="required"
            >
              <b-form-group
                :label="$t('label.assignee')"
                label-for="assignee"
              >
                <v-select
                  v-model="commitmentLocal.assignee"
                  :options="workersSupervised"
                  :reduce="(workersSupervised) => workersSupervised.value"
                  :placeholder="$t('placeholder.responsible')"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  class="assignee-selector"
                >
                  <template #option="{ title }">
                    <span class="ml-50 d-inline-block align-middle"> {{ title }}</span>
                  </template>
                  <template #selected-option="{ title }">
                    <span class="ml-50 d-inline-block align-middle"> {{ title }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- due Date -->
            <validation-provider
              #default="validationContext"
              :name="$t('label.due_date')"
              rules="required"
            >

              <b-form-group
                :label="$t('label.due_date')"
                label-for="due-date"
              >
                <flat-pickr
                  v-model="commitmentLocal.dueDate"
                  class="form-control"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Activity -->
            <b-form-group
              :label="$t('label.activity')"
            >
              <b-form-input
                v-model="commitmentLocal.activity"
                disabled
              />
            </b-form-group>

            <!-- Behaviour -->
            <b-form-group
              :label="$t('label.behaviour')"
            >
              <b-form-input
                v-model="behaviourReformatted"
                disabled
              />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
              {{ behaviourHasCommitment ? $t('message.update') : $t('message.add') }}
              </b-button>
              <b-button
                v-if="behaviourHasCommitment"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-danger"
                @click="$emit('remove-commitment')"
              >
                {{ $t('message.delete') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BModal, VBModal } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs, onMounted } from '@vue/composition-api'
import useCommon from '@/views/organization/useCommon'
import store from '@/store'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import useNotifications from '@/composables/useNotifications'
import useCommitmentHandler from './useCommitmentHandler.js'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BModal,

    // 3rd party packages
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // Other
    EditButton,
    DeleteButton,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: 'isCommitmentHandlerSidebarActive',
    event: 'update:is-commitment-handler-sidebar-active',
  },
  props: {
    isCommitmentHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    commitment: {
      type: Object,
      required: true,
    },
    behaviourHasCommitment: {
      type: Boolean,
      required: true,
    },
    clearCommitmentData: {
      type: Function,
      required: true,
    },
    handlerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const { resetCommitmentLocal, commitmentLocal, onSubmit, behaviourReformatted } = useCommitmentHandler(toRefs(props), emit)
    const { refFormObserver, getValidationState, resetForm, clearForm } = formValidation(resetCommitmentLocal, props.clearCommitmentData)
    const { getWorkersSupervisedForDropDown, workersSupervised} = useCommon()

    const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality
    const now = new Date()

    onMounted(async () => {
      getWorkersSupervisedForDropDown()
    })

    return {
      // Add New
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // Workers dropdown
      workersSupervised,

      commitmentLocal,
      commitmentFunctionality,
      now,
      behaviourReformatted,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}
</style>
