<template>
  <b-button
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    variant="flat-warning"
    v-b-tooltip.hover.v-warning.bottom="$t(i18nHoverVariable)"
    class="btn-icon rounded-circle"
    @click="$emit('clicked')"
  >
    <feather-icon icon="PlusSquareIcon" />
  </b-button>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'ViewButton',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    i18nHoverVariable: {
      type: String,
      default: "Add",
    },
  },
}
</script>

<style scoped>

</style>
