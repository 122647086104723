<template>
  <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      :variant="variant"
      class="btn-icon rounded-circle"
      v-b-tooltip.hover.v-success.bottom="$t(i18nHoverVariable)"
      @click="$emit('clicked')"
  >
    <feather-icon icon="EyeIcon" />
  </b-button>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: 'ViewButton',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    variant: {
      type: String,
      default: "flat-success",
    },
    i18nHoverVariable: {
      type: String,
      default: "View",
    },
  },
}
</script>

<style scoped>

</style>
